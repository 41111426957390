import { Context } from 'react';

type ContextExt<BaseContext, Ext> = BaseContext extends Context<infer T> ? Context<T & Ext> : never;

/**
 * Extends react context type with a given type
 *
 * Let's say we've added a `sendMetric` helper to the MetroContext,
 * by default `useContext(MetroContext)` type would have no idea about our helper.
 *
 * By using `extendContext` we can create a "new" context type, which includes our method
 * ```ts
 * const MetroContextExt = extendContext(MetroContext)<{
 *   sendMetric: (event: (typeof VIDEO_EDIT_EVENTS)[keyof typeof VIDEO_EDIT_EVENTS]) => void;
 * }>();
 * ```
 * `extendContext` only overrides the context type so that components outside our control
 * can use our extended context without modifications
 */
export const extendContext =
  <T>(context: Context<T>) =>
  <Ext>() =>
    context as unknown as ContextExt<typeof context, Ext>;
